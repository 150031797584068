* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
}

i, b {
  font-style: normal;
  font-weight: normal;
}
.g-pointer{
  cursor: pointer;
}
.g-container{
  width: 1200px;
  margin: 0 auto;
}
/*chrome--------------------------------------------start*/
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
*::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  border-radius: 8px;
}
/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 202);
  border-radius: 8px;
}
/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgb(162, 162, 163);
}
/*chrome--------------------------------------------end*/
/*IE--------------------------------------------start*/
*{
  /*三角箭头的颜色*/
  scrollbar-arrow-color: #c0c4cc;
  /*滚动条滑块按钮的颜色*/
  scrollbar-face-color: #A2A2A3;
  /*滚动条整体颜色*/
  scrollbar-highlight-color: #A2A2A3;
  /*滚动条阴影*/
  scrollbar-shadow-color: #A2A2A3;
  /*滚动条轨道颜色*/
  scrollbar-track-color: #f4f4f5;
  /*滚动条3d亮色阴影边框的外观颜色——左边和上边的阴影色*/
  scrollbar-3dlight-color:#A2A2A3;
  /*滚动条3d暗色阴影边框的外观颜色——右边和下边的阴影色*/
  scrollbar-darkshadow-color: #A2A2A3;
  /*滚动条基准颜色*/
  scrollbar-base-color: #f4f4f5;
}
/*IE--------------------------------------------end*/
