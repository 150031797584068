.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: aquamarine;
  z-index: 100000000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s;

  .icon {
    font-size: 60px;
    color: white;
  }
}
